import { Request } from 'express';

export const getBaseUrl = (req: Request): string => {
  const hostname = process.env.APP_ENV === 'local' ? req.headers.host : req.hostname;

  return req.protocol + '://' + hostname;
};

export const isPluxee = (req: Request): boolean => {
  const host = getBaseUrl(req);

  return process.env.APP_ENV === 'local' ? host.includes('3090') : host.includes('pluxee');
};
/**
 * This function create a string URL based on an array of strings.
 * Each argument in the args array will be concatenated to the next one, adding a '/' between the two if necessary
 * (ie: if the last argument doesn't finish with a '/' and if the next one doesn't start with a '/')
 *
 * @param args [String] : Parts of the final URL that will be concatenated in the order they're provided.
 */
export const concatUrl = (...args: string[]): string => {
  if (args.length < 2) {
    throw new Error('The concactUrl function expects at least 2 parts in the args param');
  } else {
    let fullUrl = args.shift();
    args.forEach((urlPart) => {
      // If neither the last part is ending with a / nor the next one starts with a / then we add one between the 2
      if (fullUrl && fullUrl.slice(-1) !== '/' && urlPart?.slice(0, 1) !== '/') {
        fullUrl = fullUrl + '/' + urlPart;
      } else if (fullUrl && fullUrl.slice(-1) === '/' && urlPart?.slice(0, 1) === '/') {
        // If the last part is ending with a / and the next one is starting with one, we should remove one
        fullUrl = fullUrl.substring(0, fullUrl.length - 1) + urlPart;
      } else {
        fullUrl = fullUrl + urlPart;
      }
    });

    return fullUrl;
  }
};

export function paramsFromUrl(
  url: string,
): { version: string; realmId: string; serviceId: string; userId: string } | null {
  const regex = /\/api\/([^/]+)\/realms\/([^/]+)(\/services\/([^/]+))?(\/users\/([^/]+))?/;
  const match = regex.exec(url);

  if (match) {
    const version = match[1];
    const realmId = match[2];
    const serviceId = match[4];
    const userId = match[6];

    return {
      version,
      realmId,
      serviceId,
      userId,
    };
  } else {
    return null;
  }
}
