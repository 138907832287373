import Head from 'next/head';
import React from 'react';

const NextHeader = (props: { isPluxee?: boolean; title: string }): JSX.Element => {
  const { isPluxee, title } = props;
  const favIcon = isPluxee ? '/favicon/pluxee-favicon.png' : '/favicon/sodexo-favicon.ico';
  const css = isPluxee ? '/css/app-pluxee.css' : '/css/app-legacy.css';
  const type = isPluxee ? 'image/png' : 'image/x-icon';

  return (
    <Head>
      <title>{`${title} | ${isPluxee ? 'PluxeeConnect' : 'SodexoConnect'}`}</title>
      <meta name='viewport' content='width=device-width, initial-scale=1.0, shrink-to-fit=no' />
      <meta name='robots' content='noindex' />
      <link key='icon' rel='shortcut icon' href={favIcon} type={type} />
      <link key='css' rel='stylesheet' href={css} />
    </Head>
  );
};

export default NextHeader;
