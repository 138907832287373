import { NoSsr, StyledEngineProvider } from '@mui/material';
import App, { AppContext } from 'next/app';
import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import NextHeader from '../front/components/next-header/next-header';
import { useInitDatadogRum } from '../hooks/datadog/useInitDatadogRum';
import { AmProps, OpProps, PageData } from '../models/interfaces/interfaces';
import { getBaseUrl } from '../utils';

export type SodexoConnectAppProps = (OpProps<PageData, any> | AmProps) & {
  Component: any;
  ddEnv: {
    appId: string;
    clientToken: string;
    env: string;
    service: string;
    site: string;
  };
  env: string;
  router: Router;
};

const SodexoConnectApp = (props: SodexoConnectAppProps): JSX.Element => {
  const { Component, ddEnv, router, ...defaultData } = props;
  const { pageContent, globalContent } = props;
  const [showCookieError, areCookieDisabled] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const isPluxee = router.pathname.includes('/pluxee');

  if (['/error', '/healthcheck'].includes(router.route)) return <Component {...defaultData} />;

  useEffect(() => {
    if (navigator.cookieEnabled === false) {
      areCookieDisabled(true);
    }
    useInitDatadogRum({ baseUrl: defaultData.baseUrl, ddEnv });
  }, []);
  useEffect(() => {
    if (document && globalContent) {
      document.documentElement.lang = globalContent.locale;
    }
  }, [globalContent]);

  const app = (
    <>
      <NextHeader
        isPluxee={isPluxee}
        title={
          pageTitle ||
          (isPluxee && pageContent?.main_title_pc) ||
          pageContent?.main_title ||
          (isPluxee ? globalContent?.title_welcome_to_pc : globalContent?.title_welcome_to_sc || 'Welcome')
        }
      />
      <NoSsr>
        {showCookieError && (
          <div className='error-header'>
            <p>
              <strong>Please enable cookies in your browser configuration to use SodexoConnect</strong>
            </p>
          </div>
        )}
        <StyledEngineProvider injectFirst>
          <Component {...defaultData} setPageTitle={setPageTitle} />
        </StyledEngineProvider>
      </NoSsr>
    </>
  );

  return app;
};

SodexoConnectApp.getInitialProps = async (appContext: AppContext) => {
  // See https://nextjs.org/docs/advanced-features/custom-app#caveats
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  const data = query?.data && typeof query.data === 'string' ? JSON.parse(query.data) : query.data;
  const { APP_ENV, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DD_ENV, DATADOG_SERVICE, DATADOG_SITE } = process.env;

  return {
    ...appProps,
    ...data,
    baseUrl: getBaseUrl(req as any),
    ddEnv: {
      appId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      env: DD_ENV,
      service: DATADOG_SERVICE,
      site: DATADOG_SITE,
    },
    env: APP_ENV,
  };
};

export default SodexoConnectApp;
