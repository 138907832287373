export const isValidEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(String(email).toLowerCase());
};

export const anonymizeEmail = (email: string): string => {
  if (!email) return undefined;
  const splitedLogin: string[] = email.split('@') || [];
  splitedLogin[0] = `${splitedLogin[0].slice(0, 1)}***${splitedLogin[0].slice(-1)}`;

  return splitedLogin.join('@');
};

export const emailWithoutAlias = (email: string): string => {
  const [localPart, domain] = email.split('@');
  const normalizedLocalPart = localPart.split('+')[0];

  return `${normalizedLocalPart}@${domain}`;
};
