export const isPasswordValid = (value: string): boolean => {
  return (
    value &&
    atLeastMinimumLength(value) &&
    atLeastOneDigit(value) &&
    atLeastOneLetter(value) &&
    atLeastOneSpecialCar(value)
  );
};

/*
 * First checks ([a-zA-Z], [0-9], [!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]) have been added to extend browser compatibility
 * Cf. https://caniuse.com/mdn-javascript_builtins_regexp_property_escapes
 */
export const atLeastOneLetter = (value: string): boolean =>
  new RegExp(/[a-zA-Z]/).test(value) || new RegExp(/\p{L}+/, 'u').test(value);

// export const atLeastOneCapitalLetter = (value: string): boolean =>
//   new RegExp(/[A-Z]/).test(value) || new RegExp(/\p{Lu}+/, 'u').test(value);

export const atLeastOneDigit = (value: string): boolean =>
  new RegExp(/[0-9]/).test(value) || new RegExp(/\p{N}+/, 'u').test(value);

export const atLeastOneSpecialCar = (value: string): boolean =>
  new RegExp(/[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]/).test(value) || new RegExp(/[\p{Z}\p{S}\p{P}]+/, 'u').test(value);

export const atLeastMinimumLength = (value: string): boolean => value.length >= 8;
