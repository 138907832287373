export enum LogLevel {
  CRITICAL = 'critical',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug',
  TRACE = 'trace',
}

export const maskField = (field: string): string => {
  if (!field) return undefined;

  return '********' + field.slice(-8);
};

export const maskSignature = (token: string): string => {
  if (!token) return undefined;

  const [header, payload] = token.split('.');

  return header + '.' + payload;
};
