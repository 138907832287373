import { datadogRum } from '@datadog/browser-rum';
import packageInfo from '../../../package.json';

export interface InitDatadogRumProps {
  baseUrl: string;
  ddEnv: {
    appId: string;
    clientToken: string;
    env: string;
    service: string;
    site: string;
  };
}

export const useInitDatadogRum = (props: InitDatadogRumProps): void => {
  const { baseUrl, ddEnv } = props;

  datadogRum.init({
    applicationId: ddEnv.appId || 'e3758ee5-18ae-4963-b3f1-9a5040d16585',
    clientToken: ddEnv.clientToken || 'puba83919883779c70d70a8a539e45f7ca7',
    env: ddEnv.env,
    service: ddEnv.service || 'sodexo-connect',
    site: ddEnv.site || 'datadoghq.com',
    version: packageInfo.version,
    sampleRate: 100,
    trackUserInteractions: true,
    trackViewsManually: true,
    trackFrustrations: true,
    sessionReplaySampleRate: 0,
    beforeSend: (event) => {
      if (event.type === 'action') {
        event.action.target.name = event.view.name + '/ ' + event.action.target.name;

        return ['BTN', 'IPT', 'SLT', 'LNK', 'CBX', 'DEBUG'].some((code) => event.action.target.name.includes(code));
      } else if (event.type === 'error') {
        let isErrorToSend = true;

        // issue on regex when users use Firefox v68
        if (
          (event.error.message === 'invalid identity escape in regular expression' ||
            event.error.message.includes('Invalid regular expression')) &&
          ['password-definition', 'password-definition-signup', 'security/password-form'].includes(event.view.name)
        ) {
          isErrorToSend = false;
        }

        if (
          ['https://searchaggr-dra.dt.dbankcloud.com', 'https://searchaggr-dre.dt.dbankcloud.com'].some((url) =>
            event.error.message.includes(url),
          )
        ) {
          isErrorToSend = false;
        }

        return isErrorToSend;
      }
    },
    allowedTracingUrls: [`${baseUrl}/`],
  });
};
